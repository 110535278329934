
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '@/lib/util'
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import EditMarca from './edit-marca.vue'
import PeopleFind from '../../../components/People/people-find.vue'

class PageMarcaRequest extends PageRequest {
  keyword: string
  nombre: string
  clienteId: string
}

@Component({
  components: {
    EditMarca,
    PeopleFind
  }
})
export default class Marca extends AbpBase {
  //filters
  pagerequest: PageMarcaRequest = new PageMarcaRequest()
  creationTime: Date[] = []
  @Prop({ type: String }) cliente: string
  createModalShow: boolean = false
  editModalShow: boolean = false
  findModalShow: boolean = false

  searchDefinition: {}[] = [
    {
      fieldName: 'Nombre',
      fieldType: 'text',
      fieldBind: 'nombre',
      value: ''
    }
  ]

  pagination: any = {}

  get list() {
    return this.$store.state.marca.list
  }
  get loading() {
    return this.$store.state.marca.loading
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit('marca/setCurrentPage', pagination.current)
    this.getpage()

    await this.getpage()
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value
      } else {
        delete this.pagerequest[element.fieldBind]
      }
    })
    await this.getpage()
    this.findModalShow = false
  }

  async hide() {
    this.findModalShow = false
  }
  async find() {
    this.findModalShow = true
  }

  async create() {
    this.createModalShow = true
  }
  editRow(item, index, button) {
    this.$store.dispatch('marca/get', item).then(() => this.edit())
  }
  deleteRow(item, index, button) {
    this.$bvModal
      .msgBoxConfirm(this.L('¿Desea eliminar esta marca?'), {
        //title: this.L('Tips'),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.L('Sí'),
        cancelTitle: this.L('No'),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
      .then(async (value) => {
        if (value) {
          await this.$store.dispatch({
            type: 'marca/delete',
            data: item
          })
          await this.getpage()
        }
      })
  }
  edit() {
    this.editModalShow = true
  }

  pageChange(page: number) {
    this.$store.commit('marca/setCurrentPage', page)
    this.getpage()
  }
  pagesizeChange(pagesize: number) {
    this.$store.commit('marca/setPageSize', pagesize)
    this.getpage()
  }
  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize

    this.pagerequest.clienteId = this.cliente;
    await this.$store.dispatch({
      type: 'marca/getAllWithImages',
      data: this.pagerequest
    })
    const pagination = { ...this.pagination }
    pagination.total = this.$store.state.marca.totalCount
    pagination.pageSize = this.$store.state.marca.pageSize
    pagination.current = this.$store.state.marca.currentPage
    this.pagination = pagination
  }
  get pageSize() {
    return this.$store.state.marca.pageSize
  }
  get totalCount() {
    return this.$store.state.marca.totalCount
  }
  get currentPage() {
    return this.$store.state.marca.currentPage
  }
  set currentPage(page) {
    this.$store.commit('marca/setCurrentPage', page)
    this.getpage()
  }

  async created() {
    this.getpage()
  }

  getImage(value: string) {
    return `data:image/png;base64,${value}`
  }

  columns = [
    {
      title: this.L('Marca'),
      scopedSlots: { customRender: 'brand' }
    },
    {
      title: this.L(''),
      dataIndex: 'imagen',
      scopedSlots: { customRender: 'img' }
    },
    {
      title: this.L('Fecha Creacion'),
      dataIndex: 'creationTime',
      scopedSlots: { customRender: 'date' }
    },
    {
      scopedSlots: { customRender: 'editBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    },
    {
      scopedSlots: { customRender: 'removeBtn' },
      width: 10,
      title: '',
      thClass: 'text-center w-25',
      tdClass: 'text-center w-25'
    }
  ]
}
