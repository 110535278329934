
import { Component, Vue, Inject, Prop, Watch } from 'vue-property-decorator'
import Util from '../../../lib/util'
import AbpBase from '../../../lib/abpbase'
import Modal from '../../../components/Modal.vue'
import BaseButton from '@/components/BaseButton.vue'
import Marca from '@/store/entities/OZONE/marca'
import PageRequest from '@/store/entities/page-request'
import Cliente from '@/store/entities/OZONE/cliente'
import SelectCliente from '../cliente/selectCliente.vue'
import { FileRecord } from 'vue-file-agent'

@Component({
  components: { Modal, BaseButton, SelectCliente }
})
export default class EditMarca extends AbpBase {
  name: 'edit-marca'
  @Prop({ type: Boolean, default: false }) value: boolean
  @Prop({ type: Boolean, default: false }) editing: boolean
  @Prop({ type: String }) id: string
  marca: Marca = new Marca()
  title: String = ''
  cliente: Cliente = null
  fileRecords = []
  uploadHeaders = { 'X-Test-Header': 'vue-file-agent' }
  fileRecordsForUpload = [] // maintain an upload queue
  disabled: boolean = false

  async save() {
    if (this.checkFormValidity()) {
      this.marca.clienteId = this.cliente.id
      if (this.fileRecords.length > 0) {
        this.marca.imagen = this.fileRecords[0].name()
        if (this.fileRecords[0].lastModified === 0) {
          this.marca.fileContent = this.fileRecords[0].url
        } else {
          this.marca.fileContent = this.fileRecords[0].urlResized
        }
      } 
      
      if (this.editing) {
        await this.$store.dispatch({
          type: 'marca/update',
          data: this.marca
        })
      } else {
        await this.$store.dispatch({
          type: 'marca/create',
          data: this.marca
        })
      }

      this.$emit('save-success')
      this.$emit('input', false)
      this.resetFields()
    }
  }
  close() {
    this.$emit('input', false)
    this.resetFields()
  }
  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }
  async shown() {
    if (this.editing) {
      this.marca = this.$store.state.marca.editMarca
      if (this.marca.fileContent.length > 0) {
        var imagen = {
          name: this.marca.imagen,
          lastModified: 0,
          sizeText: '549 KB',
          size: this.marca.fileContent.length,
          type: 'image/*',
          dimensions: {
            width: 640,
            height: 360
          },
          url: `data:image/png;base64,${this.marca.fileContent}`
        }

        this.fileRecords.push(imagen)
      }
      await this.getCliente()
    }
    if (this.editing) {
      this.title = this.L('EditMarca')
    } else {
      this.title = this.L('AddMarca')
    }
    this.setDisabled()
  }

  async getCliente() {
    await this.$store
      .dispatch({
        type: 'cliente/get',
        id: this.marca.clienteId
      })
      .then((cliente) => {
        this.cliente = cliente
      })
  }

  checkFormValidity() {
    const valid = (this.$refs.marcaForm as any).checkValidity()
    return valid
  }
  resetFields() {
    this.cliente = null
    this.marca = new Marca()
    this.fileRecordsForUpload = []
    this.fileRecords = []
  }

  async deleteUploadedFile(fileRecord) {
    await this.$store.dispatch({
      type: 'marca/deleteMarcaFileAsync',
      data: this.marca
    })
  }
  filesSelected(fileRecordsNewlySelected) {
    var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
    this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
  }
  onBeforeDelete(fileRecord) {
    var i = this.fileRecordsForUpload.indexOf(fileRecord)
    if (i !== -1) {
      // queued file, not yet uploaded. Just remove from the arrays
      this.fileRecordsForUpload.splice(i, 1)
      var k = this.fileRecords.indexOf(fileRecord)
      if (k !== -1) this.fileRecords.splice(k, 1)
    } else {
      this.$bvModal
        .msgBoxConfirm(this.L('¿Desea eliminar esta imagen?'), {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: this.L('Sí'),
          cancelTitle: this.L('No'),
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
        .then(async (value) => {
          if (value) {
            ;(this.$refs.vueFileAgent as any).deleteFileRecord(fileRecord) // will trigger 'delete' event
          }
        })
    }
  }
  fileDeleted(fileRecord) {
    var i = this.fileRecordsForUpload.indexOf(fileRecord)
    if (i !== -1) {
      this.fileRecordsForUpload.splice(i, 1)
    } else {
      this.deleteUploadedFile(fileRecord)
    }
  }

  setDisabled() {
    this.disabled = !abp.auth.isAnyGranted('OZONE.Marcas.Edit', 'OZONE.Marcas.Create', 'OZONE.Marcas.Delete')
  }

  marcaRule = {
    nombre: {
      required: { required: true, min: 2, max: 32 },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Nombre')),
      placeholder: this.L('Nombre'),
      trigger: 'blur'
    },
    cliente: {
      required: { required: true },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Cliente')),
      placeholder: this.L('Cliente'),
      trigger: 'blur'
    },
    imagen: {
      required: { required: false },
      message: this.L('ThisFieldIsRequired', undefined, this.L('Imagen')),
      placeholder: this.L('Imagen'),
      trigger: 'blur'
    }
  }
}
